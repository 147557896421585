.flipCard {
    background-color: transparent;
    flex: 1 0 clamp(15rem, 25vw, 30rem); /* Clamp function for flexible width */
    max-width: 30rem; /* Maximum width */
    min-width: 15rem; /* Minimum width */
    height: clamp(12rem, 15vw, 18rem); /* Clamp function for flexible height */
    perspective: 1000px;
    margin: 0.625rem; /* Added margin for spacing between cards */
    box-sizing: border-box; /* Ensure margin is included in the element's size */
    position: relative; /* Ensure positioning context for absolute elements */
    border-radius: 1rem; /* Add border-radius */
}

.flipCardInner {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform-origin: center center; /* Ensures the pivot is the center */
    position: absolute; /* Make it absolute to center it correctly */
    top: 0;
    left: 0;
}

.flipCard:hover .flipCardInner {
    transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem; /* Adjusted padding to fit content */
    box-sizing: border-box; /* Ensure the padding is included in the element's size */
    border-radius: 1rem; /* Add border-radius */
}

.flipCardFront {
    background-color: #bbb;
    color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; /* Ensure the background image does not repeat */
}

.flipCardBack {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
}

.content {
    font-size: 1.125rem;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add some shadow to the text for better readability */
    width: 100%; /* Ensure text takes the full width */
    text-align: center; /* Center the text */
    z-index: 1; /* Ensure the text is on top of the background */
    position: relative;
}

.flipCardFront::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: inherit;
    background-position: inherit;
    background-repeat: inherit;
    z-index: 0;
    border-radius: 1rem; /* Add border-radius */
}

@media (max-width: 768px) {
    .flipCard {
      height: 15rem; /* Fixed height for better mobile experience */
    }
    
    .content {
      font-size: 1rem; /* Smaller font size for better readability on mobile */
    }
}

@media (max-width: 480px) {
    .flipCard {
      height: 12rem; /* Further reduced height for smaller screens */
    }
    
    .content {
      font-size: 0.875rem; /* Smaller font size for smaller screens */
    }
}
