.callNowTab {
  position: fixed;
  display: flex;
  bottom: 1rem;
  width: auto;
  max-width: 90%; /* Limit width to prevent overflow */
  background-color: #fe5b01;
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 2rem;
}

.text {
  text-decoration: none;
  color: white;
}

/* Media query for devices with a max width of 768px */
@media (max-width: 768px) {
  .callNowTab {
    bottom: 0.5rem; /* Reduce bottom margin for closer edge placement */
    font-size: 14px; /* Smaller font size for smaller screens */
    padding: 0.8rem; /* Adjust padding to be less bulky */
    border-radius: 1rem; /* Smaller radius for a subtler curvature */
  }
}
