.services1 {
   align-self: stretch;
   position: relative;
   font-weight: 800;
   display: inline-block;
   height: 3rem;
   flex-shrink: 0;
   margin-bottom: 1.2rem; /* Add space below the title */
   margin-top:3rem;
 }
 
 .servicesWrapper {
   align-self: stretch;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 1.2rem 0;
   box-sizing: border-box;
   background-color: #f3f3f3;
 }
 
 .commercialHvacParent {
   display: flex;
   flex-wrap: wrap;
   justify-content: center; /* Center items horizontally */
   gap: 1.25rem;
   padding: 1.25rem;
   align-items: flex-start; /* Align items to the top */
   width: 100%; /* Ensure full width */
 }
 
 .services {
  width: 100%;
  position: relative;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1.25rem 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 2.5rem;
  color: #454471;
  font-family: Inter;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 3rem;

 }
 