@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap');

.about {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  font-family: 'Inter', sans-serif; /* Apply Inter font */

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .paragraph {
      margin-bottom: 10px;
      font-size: clamp(0.7rem, 1.5vw, 3rem);
      color: #333; /* Updated text color */
    }

    h1 {
      font-size: clamp(1.25rem, 2.5vw, 4rem);
      color: #251424; /* Updated heading color */
      font-weight: 800; /* Updated font weight */
    }
  }
}

.aboutImage {
  width: clamp(30%, 40%, 60%);
  height: clamp(50%, 70%, 90%);
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: right;
    border-radius: 2rem;
  }
}
