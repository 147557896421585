@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.heroWrapper {
  width: 100%;
  height: 100vh; /* Ensures the hero section takes the full viewport height */
  position: relative;
  overflow: hidden;
}

.component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: background-image 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  &.left {
    animation: slideInFromLeft 1s ease-in-out;
  }
  &.right {
    animation: slideInFromRight 1s ease-in-out;
  }
}

.vectorParent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.leftButton, .rightButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.leftButton {
  left: 10px;
}

.rightButton {
  right: 10px;
}

.vectorImage {
  width: 33.5px;
  height: 92px;
  object-fit: contain;
}
