@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.navbar {
    background-color: #251424;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    font-size: clamp(0.9rem, 2.5vw, 1.5rem);
    color: #f3f3f3;
    width: 90dvw;
    border-radius: 1.5rem;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0.5rem;
    font-family: 'Inter', sans-serif; /* Apply Inter font */
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(-100%);
    opacity: 0;
}

.navbar.open {
    transform: translateY(0);
    opacity: 1;
}

/* Responsive and accessible logo sizing */
.logo {
    height: clamp(50%, 1vw, 80%);
    width: auto; /* Maintain aspect ratio */
    cursor: pointer;
}

/* Navigation items container */
.navItems {
    display: flex;
    gap: clamp(1rem, 1vw, 2rem);
    align-items: center;
    justify-content: center;
}

/* Individual navigation items */
.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    flex-shrink: 0;
    cursor: pointer;
}

/* Contact button wrapper with responsive padding */
.contactWrapper {
    border-radius: 30px;
    background-color: #fe5b01;
    display: flex;
    padding: 0.5rem 1rem 0.5rem 1rem;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    text-decoration: none;
    color: white;
    font-family: 'Inter', sans-serif; /* Apply Inter font */
}

/* Contact navigation item */
.contactNavItem {
    width: 143px;
    height: 46px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
